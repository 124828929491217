export interface IComponentPropertySyncConfig {
  componentType:any;
  propertiesToSync:string[];
}

// Define the properties that components need syncing when hosted in a popup
export const ComponentPropertySyncConfig: IComponentPropertySyncConfig [] = 
  [
        { componentType: 'DZIViewer', propertiesToSync: ['dziViewerPopupState', 'selectedDziImage', 'userPermissions', 'defects', 'imageStatuses', 'organisationUsers', 'isDefectModeEnabled', 'isTagModeEnabled', 'updatedImageIds', 'imageDefectCount']},
        { componentType: 'VAAThreeDViewerWrapper', propertiesToSync: ['threeDViewerConfig', 'threeDViewerPopupState','mapDataLayers', 'flyToLocation', 'viewAssetIn2d','userPermissions']},
  ];