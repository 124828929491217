import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles'

import './App.css';
import './MaterialUIStyleOverrides.css';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import { VAAContainer } from './components/Containers/VAAContainer/VAAContainer';
import { PopupContainer } from './components/Containers/PopupContainer/PopupContainer';
import { DZIViewerContainer } from './components/Containers/DZIViewerContainer/DZIViewerContainer';

import { SignOut } from './components/SignOut/SignOut';

import { vaaTheme } from './vaaTheme'

function App() {
  return (
     <ThemeProvider theme={vaaTheme}>
        <div className="App">  
            <Router>
                <Switch>
                      <Route path="/dziviewerstandalone/:id" component={DZIViewerContainer} />
                      <Route exact path="/dziviewer" render={(props) => <PopupContainer {...props} hostedComponentType={`DZIViewer`} />} />  
                      <Route exact path="/threedviewer" render={(props) => <PopupContainer {...props} hostedComponentType={`VAAThreeDViewerWrapper`} />} />  
                      <Route path="/signout" component={SignOut} />
                      <Route path="/" component={VAAContainer} />
               </Switch>
            </Router>
        </div>
    </ThemeProvider>
  );
}

export default App;
