import React, { useState, useCallback } from 'react';
import { postWithAuthorisationHeader } from "../../services/AuthenticationService";
import { IThreeDViewerConfig } from '../../models/IThreeDViewerConfig';
import { IThreeDViewerState } from '../../models/IThreeDViewerState';
import { IMapLayer } from '../../models/IMapLayer';
import { ICoordinate } from '../../models/ICoordinate';
import { IThreeDViewerFeature } from '../../models/IThreeDViewerFeature';
import { ThreeDViewer } from '../ThreeDViewer/ThreeDViewer';
import { SegmentDetailsBasic } from '../SegmentDetailsBasic/SegmentDetailsBasic';

interface IProps {
    mapDataLayers: IMapLayer[][] | null;
    flyToLocation: ICoordinate | null;
    config: IThreeDViewerConfig | null;
    getViewerStateFlag?: number;
    viewerState?: IThreeDViewerState | null;
    onGetViewerState?: Function;
    containerHeaderSize?: number;
    viewAssetIn2d: boolean;
    onSegmentUrlClick?: Function;
    userPermissions: string[];
}

// This component wraps the ThreeDViewer component. The ThreeDViewer component is shared between VAA and AIMS and is designed to be portable.  
// This is the place to put VAA specific functionality.

export function VAAThreeDViewerWrapper(props: IProps) {
    const [features, setFeatures] = useState<IThreeDViewerFeature[] | null>(null);
    const [selectedFeatureDetailsElement, setSelectedFeatureDetailsElement] = useState<any | null>(null);

    const getFeatures = useCallback( async (position:ICoordinate) => {

        // Fetch segments from the api and turn them into ThreeDViewer features

        // Call the api to get any segments within distance from the coordinate

        let searchDistanceMeters = 500;

        let data = {
            distanceMeters: searchDistanceMeters,
            latitude: position.latitude,
            longitude: position.longitude
        }

        let segmentUrl = process.env.REACT_APP_VAA_API_URL + "segment/fetchByDistance/";

        let result = await postWithAuthorisationHeader(segmentUrl,data);
        if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null) {

            // Create features to pass to ThreeDViewer 
            let featuresToCreate:IThreeDViewerFeature[] = [];

            let defaultFeatureHeight = 200;
            let defaultColour = "#FF0000";
            let iconPath = process.env.PUBLIC_URL + "/images/custom_pole.png";

            result.data.forEach( (asset:any) => featuresToCreate.push( 
                {
                    id: asset.id,
                    name: asset.externalReferenceId,
                    description: 'Segment',
                    latitude: asset.lat,
                    longitude: asset.lon,
                    height: defaultFeatureHeight,
                    colour: defaultColour,
                    iconPath: iconPath
                } ));
                
            setFeatures(featuresToCreate);
        }
    }, []);


  // A feature has been selected in the ThreeDViewer component
  const onFeatureSelected = useCallback( async (segmentId:number) => {

        let ids: number[] = [Number(segmentId)];

        let segmentUrl = process.env.REACT_APP_VAA_API_URL + "segment/fetchBySegmentIds/";

        let result = await postWithAuthorisationHeader(segmentUrl,ids);
        if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null) {

            let segment:any = result.data[0];

            // Construct a SegmentDetailsBasic that will be shown over the ThreeDViewer window
            let detailsElement = <SegmentDetailsBasic
                                        enableUrlViewing={props.userPermissions.includes('CanViewAssetUrlVAA')}
                                        segment={segment}
                                        onClose={() => {
                                            setSelectedFeatureDetailsElement( null );
                                        }}
                                        onSegmentUrlClick={(url: string) => {
                                            if (props.onSegmentUrlClick) props.onSegmentUrlClick(url);
                                        }}
                                    />;

            setSelectedFeatureDetailsElement( detailsElement );
        }
    }, [props]);

    return (<div style={{ height: '100%', width: '100%' }}>
        <ThreeDViewer
            selectedFeatureDetailsElement={selectedFeatureDetailsElement}
            onFeatureSelected={ (segmentId:any) => onFeatureSelected(segmentId)}
            features={features}
            containerHeaderSize={props.containerHeaderSize}
            apiUrl={process.env.REACT_APP_VAA_API_URL !== undefined ? process.env.REACT_APP_VAA_API_URL : ""}
            cesiumToken={process.env.REACT_APP_CESIUM_TOKEN !== undefined ? process.env.REACT_APP_CESIUM_TOKEN : ""}
            flyToLocation={props.flyToLocation}
            viewerState={null}
            mapDataLayers={props.mapDataLayers}
            config={props.config} 
            getViewerStateFlag={props.getViewerStateFlag}
            viewAssetIn2d={props.viewAssetIn2d}            
            onGetViewerState={ (state:any) => {if (props.onGetViewerState) props.onGetViewerState(state) }}
            onCameraMoveEnd={ (cameraMoveEnd:any) => getFeatures(cameraMoveEnd.position) }
        />
    </div>);
}